import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import checklistdata from '../../../../../assets/data/proposed-checklist.json';
import moment from 'moment';
import { FirestoreDataService } from 'src/app/services/firestore-data.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AngularFirestore, AngularFirestoreCollection,AngularFirestoreDocument } from '@angular/fire/firestore';
import User from '../../../../../models/user';
import * as firebase from 'firebase/app';

@Component({
	selector: 'app-checklist',
	templateUrl: './checklist.component.html',
	styleUrls: ['./checklist.component.scss']
})

export class ChecklistComponent implements OnInit {
	checklist;
	selectedTask;
	sidebarOpen;
	datesForm: FormGroup;
	slideOutPane;
	date:String

	userEmail:String;
	uid:String;

	selectedPracticums:AngularFirestoreCollection<this>;

	private practicumCollection: AngularFirestoreCollection<this>;
	private practicumSection: AngularFirestoreCollection<this>;

	constructor(private fb: FormBuilder, private renderer: Renderer2,public db: AngularFirestore,
		private firestoreData: FirestoreDataService,
		private auth: AuthenticationService) {
		this.checklist = checklistdata;
		this.selectedTask = {};
		this.sidebarOpen = false;
		this.datesForm = this.fb.group({
			course0date: '',
			course1date: '',
			course2date: '',
			course3date: ''
		});
	}

	ngOnInit(): void {
		this.onDatesFormChanges();
		this.userEmail = localStorage.getItem('userEmail');

		// console.log('this.userEmail:'+this.userEmail);

		firebase.auth().getRedirectResult().then(result => {
			var user = result.user;
			if (user) {
				console.log('user:',user);
				localStorage.setItem('userEmail',user.email);
				// Save user.
			}
			var user = result.user;
			user.getIdToken().then(function(idToken) {  // <------ Check this line
			  console.log("idtoken" + idToken); // It shows the Firebase token now
		   });

			if (result) {
				var token = result.credential;
				console.log('token',token);
				// localStorage.setItem('token',result.credential);
			}
			var value;
			value = 'init';
			this.getPracticumFromDatabase(value);
		}).catch(e => {
		});
	}

	ngAfterViewChecked() {
		this.slideOutPane = document.querySelector('.checklist-detail');
		this.toggleDatesFormSelects();
	}

	getDateLabel(date) {
		let label = '';

		this.checklist.courseTerms.forEach(term => {
			if (term.value === date) label = term.label;
		});

		return label;
	}

	getCompletedSubsections(section) {
		let self = this;

		return section.subsections.filter(s => s.tasks.length === self.getCompletedTasks(s)).length;
	}

	getCompletedTasks(subsection) : number { return subsection.tasks.filter(t => t.complete).length; }

	getCompletedCourses() : number {
		let completedCoursesCount = this.checklist.courses.filter(c => c.tasks.length === this.getCompletedTasks(c)).length;
		return completedCoursesCount;
	}

	getTotalCompleted() {
		let result = this.getCompletedCourses();

		this.checklist.sections.forEach(s => result += this.getCompletedSubsections(s));

		return result;
	}

	taskClick(task) {
		this.selectedTask = task;
		this.sidebarOpen = true;
		disableBodyScroll(this.slideOutPane);

		window.dataLayer.push({
			'taskName': task.title,
			'event': 'detailsClicked'
		});
	}

	closeSidebar() {
		this.sidebarOpen = false;
		enableBodyScroll(this.slideOutPane);
		this.slideOutPane.scrollTop = 0;
	}

	removeEmpty(obj: Object) {
		Object.keys(obj).forEach(key => (obj[key] == null || obj[key] === '') && delete obj[key]);
	}

	practicumDatesClickHandler() {
		window.dataLayer.push({'event': 'setPracticumDatesClicked'});
	}

	reviewClickHandler(ev) {
		ev.preventDefault();

		window.dataLayer.push({'event': 'reviewYourDegreeAuditClicked'});
		window.open(ev.target.href);
	}

	contactClickHandler(ev) {
		ev.preventDefault();

		window.dataLayer.push({'event': 'contactYourStudentSuccessAdvisorClicked'});
		window.open(ev.target.href);
	}

	expandClickHandler(title) {
		window.dataLayer.push({
			'sectionName': title,
			'event': 'sectionExpanded'
		});
	}

	trackByFunction(idx, item) {
		return item.$key;
	}

	taskCompleteClickHandler(task, ev) {
		ev.preventDefault();
		ev.stopPropagation();
		task.complete = !task.complete;

		window.dataLayer.push({
			'taskName': task.title,
			'event': task.complete ? 'taskChecked' : 'taskUnchecked'
		});

		this.userEmail = localStorage.getItem('userEmail');

		if(this.userEmail!=undefined){
			var userRef = this.db.collection('user').doc(firebase.auth().currentUser.uid);
			// userRef.update({uid:firebase.auth().currentUser.uid,email_address:this.userEmail, banner_id:'A00254963',Practicum:this.selectedPracticums })
			userRef.valueChanges();
			userRef.set({uid:firebase.auth().currentUser.uid,Sections:this.checklist.sections,Practicum:this.checklist.courses},{merge: true})
			.catch(function(error) {
				console.error("Error writing document: ", error);
			});
		}
	}

	toggleDatesFormSelects() {
		Object.keys(this.datesForm.controls).forEach((k, idx) => {
			if (idx === 0) return;

			let previousControl = this.datesForm.get(Object.keys(this.datesForm.controls)[idx-1]);
			let control = this.datesForm.get(k);

			return previousControl.value !== '' ? control.enable() : control.disable();
		});
	}

	onDatesFormChanges(): void {
		let self = this;

		console.log('dates changed');

		this.datesForm.valueChanges.subscribe(formObject => {

			self.removeEmpty(formObject);

			self.checklist.courseTerms.forEach(term => term.disabled = false);

			if (Object.keys(formObject).length === 0) return;

			let selectedDates = Object.keys(formObject).map(k => formObject[k]);

			selectedDates.forEach(d => {
				self.checklist.courseTerms.forEach(term => {
					if (moment(term.value, 'MM/DD/YYYY').isBefore(moment(d, 'MM/DD/YYYY')) || term.value === d) term.disabled = true;
				});
			});
		});
	}

	onDatesSubmit() {

		console.log("firebase.auth().currentUser: " +firebase.auth().currentUser.uid);
		this.uid = firebase.auth().currentUser.uid;
		console.log("firebase.auth().currentUse.userEmail: " +firebase.auth().currentUser.email);
		this.userEmail = firebase.auth().currentUser.email;

		let datesFormValues = this.datesForm.value;

		window.dataLayer.push({
			'courseDate1': datesFormValues['course0date'],
			'courseDate2': datesFormValues['course1date'],
			'courseDate3': datesFormValues['course2date'],
			'courseDate4': datesFormValues['course3date'],
			'event': 'submitClicked'
		});

		this.checklist.courses.forEach((course, idx) => {

			if (!datesFormValues['course' + idx + 'date']) {
				course.date = '';
				return;
			}

			let date = datesFormValues['course' + idx + 'date'];
			let term = this.checklist.courseTerms.filter(t => t.value === date)[0];
			let taskInstructionsIndex = 0;

			if(date == "No date selected"){
				course.date = "";
			}else{
				course.date = term.value;
			}


			course.tasks.forEach(task => {
				if (!task.instructions) return;

				switch (taskInstructionsIndex) {
					case 0:
					task.byDate = moment(date, 'M-D-YYYY').subtract(9, 'months').format('MMMM YYYY');
					break;

					case 1:
					task.byDate = moment(date, 'M-D-YYYY').subtract(6, 'months').format('MMMM YYYY');
					break;

					case 2:
					task.byDate = moment(date, 'M-D-YYYY').subtract(3, 'months').format('MMMM YYYY');
					break;
				}

				taskInstructionsIndex++;
			});
		});

		this.db.collection('user').doc(firebase.auth().currentUser.uid)
		.set({uid:firebase.auth().currentUser.uid,email_address:this.userEmail,Sections:this.checklist.sections,Practicum:this.checklist.courses});


		$('#modal-set-practicums .close').trigger('click');
	}


	compareFn(c1, c2) {
		// console.log(a, b, a && b && a.date == b.date);
		// return a && b && a.date == b.date;

		console.log(c1,c2,c1 && c2 ? c1.id === c2.id : c1 === c2);
		return c1 && c2 ? c1.id === c2.id : c1 === c2;

	}

	//        compareFn(c1: any, c2:any) {
		//         return c1 && c2 ? c1.id === c2.id : c1 === c2;
		//    }



		loadChecklist(checklistCourses, checklistSections) {
			if(checklistCourses!=null){
				this.checklist.courses = checklistCourses;
				console.log('checklist.sections:'+this.checklist.sections);
				for(var i=0; i < this.checklist.courses.length; i++){

					if(this.checklist.courses[i].selected === true){
						console.log('this.checklist.courses[i]',this.checklist.courses[i]);
					}
				}
				console.log('checklist.courses: ' + this.checklist.courses);
			}
			if(checklistSections!=null){
				this.checklist.sections = checklistSections;
			}
		}


		getPracticumFromDatabase(value){
			// this.auth.authenticated();
			this.userEmail = localStorage.getItem('userEmail');

			console.log("userEmail: " +this.userEmail);

			var userData = this.db.collection("user", ref => ref.where('email_address', '==', this.userEmail)).valueChanges();
			var user
			var cookiePracticums = [];
			userData.subscribe(users => {
				user = users[0];
				if(user!=undefined) {
					console.log('user.Practicum', user.Practicum);
					this.practicumCollection = user.Practicum;
					this.practicumSection = user.Sections

					console.log('this.practicumCollection:' + this.practicumCollection);
					console.log('this.practicumSection:' + this.practicumSection);

					console.log(value);

					if (value == 'init'){
						this.loadChecklist(this.practicumCollection, this.practicumSection);
					}
				}

			});

		}


	}
