import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user;

  constructor(private auth: AuthenticationService, private router: Router) {
    this.user = auth.authInfo;
  }


  ngOnInit() {
    // console.log("user", this.user);
  }

  login() {
    window.dataLayer.push({
      'event': 'logInClicked'
    });

    this.auth.login();
  }

  logout() {
    this.auth.logout();
  }

  getUser() {
    return this.user;
  }

}
