import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { LoginComponent } from './components/login/login.component';

// Services
import { AuthenticationService } from './services/authentication.service';
import { AuthGuardService } from './services/auth-guard.service';

// Components
import { ProtectedComponent } from './components/protected/protected.component';

import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { SubNavigationComponent } from './modules/partials/sub-navigation/sub-navigation.component';
import { WelcomeComponent } from './modules/pages/setup/welcome/welcome.component';
import { PracticumDateComponent } from './modules/pages/setup/practicum-date/practicum-date.component';
import { PersonalizationComponent } from './modules/pages/setup/personalization/personalization.component';
import { ChapterHeroComponent } from './modules/partials/chapter-hero/chapter-hero.component';
import { OverviewComponent } from './modules/pages/professional-skills/overview/overview.component';
import { ProfessionalImageComponent } from './modules/pages/professional-skills/professional-image/professional-image.component';
import { DifferentiateComponent } from './modules/pages/professional-skills/differentiate/differentiate.component';
import { ResumeComponent } from './modules/pages/professional-skills/resume/resume.component';
import { InterviewComponent } from './modules/pages/professional-skills/interview/interview.component';
import { LinkedinComponent } from './modules/pages/professional-skills/linkedin/linkedin.component';
import { IntroDividerComponent } from './modules/pages/course-requirements/intro-divider/intro-divider.component';
import { PreceptorComponent } from './modules/pages/course-requirements/preceptor/preceptor.component';
import { SiteComponent } from './modules/pages/course-requirements/site/site.component';
import { OverviewTipsComponent } from './modules/pages/tips-preceptor/overview-tips/overview-tips.component';
import { PreceptorTipsComponent } from './modules/pages/tips-preceptor/preceptor-tips/preceptor-tips.component';
import { SiteTipsComponent } from './modules/pages/tips-preceptor/site-tips/site-tips.component';
import { OverviewStepsComponent } from './modules/pages/next-steps/overview-steps/overview-steps.component';
import { ScheduleComponent } from './modules/pages/view-my-schedule/schedule/schedule.component';
import { HelpComponent } from './modules/pages/help/help.component';
import { ChecklistComponent } from './modules/pages/view-my-schedule/checklist/checklist.component';
import { DatesModalComponent } from './modules/pages/view-my-schedule/dates-modal/dates-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Routes
import { AppRoutingModule } from './app-routing.module';
import { PageNavDesktopComponent } from './modules/partials/page-nav-desktop/page-nav-desktop.component';
import { HomeComponent } from './modules/pages/home/home.component';
import { ChecklistDetailComponent } from './modules/pages/view-my-schedule/checklist/checklist-detail/checklist-detail.component';
import { SafeHtmlPipe } from './safe-html.pipe';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProtectedComponent,
    HeaderComponent,
    FooterComponent,
    SubNavigationComponent,
    WelcomeComponent,
    PracticumDateComponent,
    PersonalizationComponent,
    ChapterHeroComponent,
    OverviewComponent,
    ProfessionalImageComponent,
    DifferentiateComponent,
    ResumeComponent,
    InterviewComponent,
    LinkedinComponent,
    IntroDividerComponent,
    PreceptorComponent,
    SiteComponent,
    OverviewTipsComponent,
    PreceptorTipsComponent,
    SiteTipsComponent,
    OverviewStepsComponent,
    ScheduleComponent,
    HelpComponent,
    ChecklistComponent,
    DatesModalComponent,
    PageNavDesktopComponent,
    HomeComponent,
    ChecklistDetailComponent,
    SafeHtmlPipe
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthenticationService,
    AuthGuardService,
    SubNavigationComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
