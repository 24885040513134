import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preceptor',
  templateUrl: './preceptor.component.html',
  styleUrls: ['./preceptor.component.scss']
})
export class PreceptorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
