import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, RouterModule, Route } from "@angular/router";
import { map, filter, scan, subscribeOn } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { BreadcrumbService } from '../../../services/breadcrumb/breadcrumb.service';
import { IBreadcrumbService, IBreadcrumb } from '../../../services/ibreadcrumb/ibreadcrumb.service';
import menudata from '../../../../assets/data/sections.json';


@Component({
  selector: 'app-chapter-hero',
  templateUrl: './chapter-hero.component.html',
  styleUrls: ['./chapter-hero.component.scss'],
  providers: [
    { provide: IBreadcrumbService, useClass: BreadcrumbService }
  ]
})
export class ChapterHeroComponent implements OnInit {

  constructor(
    private iBreadcrumbService: IBreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  show = false;
  sections = menudata.sections;
  pagedata;
  btmnavitems = menudata.btmnavitems;
  crumbs = [];
  //url: string = this.activatedRoute.snapshot.url[0].path + '/' + this.activatedRoute.snapshot.children[0].url[0].path;

  ngOnInit() {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        let root: ActivatedRoute = this.activatedRoute.root;
        let url = '';

        this.iBreadcrumbService.getBreadcrumbs(root, url)
          .subscribe(crumb => {

            if (crumb.currentURL !== "") {
              let pagename;
              pagename = crumb.subsections[0]['pagename'];

              if (crumb.currentURL === pagename) {
                this.show = true;
                this.pagedata = crumb.subsections[0];
              }
            }
          });
      });
  }
}
