import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-practicum-date',
  templateUrl: './practicum-date.component.html',
  styleUrls: ['./practicum-date.component.scss']
})
export class PracticumDateComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {

    //console.log(this.router.url);
  }

}
