import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../app/services/authentication.service';
import { Router } from '@angular/router';

declare global {
    interface Window { dataLayer }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {
    title = 'nursing-preceptor';
    user;

    constructor( private auth: AuthenticationService, private router: Router ) {
        this.user = auth.authInfo;
    }

    ngOnInit() {
        if (localStorage.getItem('loginStarted')) {
            this.router.navigate(['/checklist']);
        }

        // this.auth.getRedirectedResult()
        // .then(result => {
        //     console.log(result);
        // })
        // .catch(error => console.log(error))

        this.user.subscribe(user => {
            if (user && user.uid) {
                window.dataLayer.push({
                    'userId': user.uid,
                    'event': 'setUserId'
                });
            }
        });
    }
}
