import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';

import { IBreadcrumbService, IBreadcrumb } from 'src/app/services/ibreadcrumb/ibreadcrumb.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb/breadcrumb.service';


@Component({
  selector: 'app-page-nav-desktop',
  templateUrl: './page-nav-desktop.component.html',
  styleUrls: ['./page-nav-desktop.component.css'],
  providers: [
    { provide: IBreadcrumbService, useClass: BreadcrumbService }
  ]
})
export class PageNavDesktopComponent implements OnInit {

  constructor(
    private iBreadcrumbService: IBreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private router: Router
    ) { }

  crumb;
  show = false;

  current;
  showNav = false;
  nextHref;
  prevHref;
  isFirst = false;
  isLast = false;

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        let root: ActivatedRoute = this.activatedRoute.root;
        let url = '';

        this.iBreadcrumbService.getBreadcrumbs(root, url)
          .subscribe(c => {
            if (c.section != "") {
              this.crumb = c;
              this.show = true;
              this.showNav = (this.crumb.subsections.length > 1);

              if(this.showNav) {
                this.crumb.subsections.map((subsection, i) => {

                  if(this.crumb.currentURL == subsection.pagename) {
                    this.current = i+1;

                    if(i==0) {
                      this.isFirst = true;
                      this.nextHref = this.crumb.subsections[i+1].pagename;
                    } else if(i==this.crumb.subsections.length-1) {
                      this.isLast = true
                      this.prevHref = this.crumb.subsections[i-1].pagename;
                    } else
                    if(i>0 && i<this.crumb.subsections.length) {
                      // not first, not last
                      this.prevHref = this.crumb.subsections[i-1].pagename;
                      this.nextHref = this.crumb.subsections[i+1].pagename;
                    }
                  }

                })
              }

            }
          });
    });
  }
}
