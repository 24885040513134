import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { LoginComponent } from './components/login/login.component';
import { ProtectedComponent } from './components/protected/protected.component';
import { AuthGuardService } from './services/auth-guard.service';


// setup
import { WelcomeComponent } from './modules/pages/setup/welcome/welcome.component';
import { PracticumDateComponent } from './modules/pages/setup/practicum-date/practicum-date.component';
import { PersonalizationComponent } from './modules/pages/setup/personalization/personalization.component';
// profesional-skills
import { OverviewComponent } from './modules/pages/professional-skills/overview/overview.component';
import { ProfessionalImageComponent } from './modules/pages/professional-skills/professional-image/professional-image.component';
import { DifferentiateComponent } from './modules/pages/professional-skills/differentiate/differentiate.component';
import { ResumeComponent } from './modules/pages/professional-skills/resume/resume.component';
import { InterviewComponent } from './modules/pages/professional-skills/interview/interview.component';
import { LinkedinComponent } from './modules/pages/professional-skills/linkedin/linkedin.component';
// course-requirements
import { IntroDividerComponent } from './modules/pages/course-requirements/intro-divider/intro-divider.component';
import { PreceptorComponent } from './modules/pages/course-requirements/preceptor/preceptor.component';
import { SiteComponent } from './modules/pages/course-requirements/site/site.component';
// tips-preceptor
import { OverviewTipsComponent } from './modules/pages/tips-preceptor/overview-tips/overview-tips.component';
import { PreceptorTipsComponent } from './modules/pages/tips-preceptor/preceptor-tips/preceptor-tips.component';
import { SiteTipsComponent } from './modules/pages/tips-preceptor/site-tips/site-tips.component';
// next-steps
import { OverviewStepsComponent } from './modules/pages/next-steps/overview-steps/overview-steps.component';
// view-my-schedule
import { ScheduleComponent } from './modules/pages/view-my-schedule/schedule/schedule.component';
import { ChecklistComponent } from './modules/pages/view-my-schedule/checklist/checklist.component';
// help
import { HelpComponent } from './modules/pages/help/help.component';


import { HomeComponent } from './modules/pages/home/home.component';



// Routes
const appRoutes: Routes = [
//  { path: '', pathMatch: 'full', redirectTo: '/' },
    // { path: '', component: LoginComponent },
//  { path: 'protected', component: ProtectedComponent, canActivate: [AuthGuardService] },

  // redirects
  { path: '', component: HomeComponent,  data: {breadcrumb: 'Home'} },
  { path: 'index', redirectTo:'',  pathMatch: 'full'},

  // setup
  { path: 'setup', component: WelcomeComponent, data: {breadcrumb: 'Setup'} },
  { path: 'checklist', component: ChecklistComponent, data: {breadcrumb: 'Checklist'}, canActivate: [AuthGuardService] },
  { path: 'welcome', component: WelcomeComponent, data: {breadcrumb: 'Welcome to' } },
  { path: 'practicum-date', component: PracticumDateComponent, data: { breadcrumb: 'Practicum Date'}} ,
  { path: 'personalization', component: PersonalizationComponent, data: { breadcrumb: 'Personalization'}} ,
  // professional-skills
  { path: 'professional-skills', component: OverviewComponent, data: { breadcrumb: 'Professional Skills' }},
  { path: 'overview', component: OverviewComponent, data: { breadcrumb: 'Overview'} },
  { path: 'professional-image', component: ProfessionalImageComponent, data: { breadcrumb: 'Professional Image'} },
  { path: 'differentiate', component: DifferentiateComponent, data: { breadcrumb: 'Differentiate'} },
  { path: 'resume', component: ResumeComponent, data: { breadcrumb: 'Resume'} },
  { path: 'interview', component: InterviewComponent, data: { breadcrumb: 'Interview'} },
  { path: 'linkedin', component: LinkedinComponent, data:  { breadcrumb: 'LinkedIn'} },
  // course-requirements
  { path: 'course-requirements', component: IntroDividerComponent, data: { breadcrumb: 'Overview'}},
  { path: 'overview-divider', component: IntroDividerComponent, data: { breadcrumb: 'Overview'} },
  { path: 'preceptor', component: PreceptorComponent, data: { breadcrumb: 'Preceptor'} },
  { path: 'site', component: SiteComponent, data: {breadcrumb: 'Site'} },

  // tips-for-finding-preceptor
  { path: 'tips-for-finding-preceptor', component: OverviewTipsComponent, data: { breadcrumb: 'Overview'} },
  { path: 'overview-tips', component: OverviewTipsComponent, data: {breadcrumb: 'Overview'} },
  { path: 'preceptor-tips', component: PreceptorTipsComponent, data: {breadcrumb: 'Preceptor Tips'} },
  { path: 'site-tips', component: SiteTipsComponent, data: {breadcrumb: 'Site Tips'} },
  // next-steps
  { path: 'next-steps', component: OverviewStepsComponent, data: {breadcrumb: 'Next Steps'} },
  { path: 'overview-steps', component: OverviewStepsComponent, data: {breadcrumb: 'Overview'} },
  // view-my-schedule
  { path: 'schedule', component: ScheduleComponent },
  // help
  { path: 'help', component: HelpComponent }
]

export const AppRoutingModule = RouterModule.forRoot(appRoutes);

