import { Injectable } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Observable } from 'rxjs';

export interface IBreadcrumb {
  section: string;
  subsections: {}[];
  currentURL: string;
}

@Injectable()
export abstract class IBreadcrumbService {
  abstract getBreadcrumbs(route: ActivatedRoute, url:string): Observable<IBreadcrumb> ;
  abstract sendBreadcrumbs(routepath:string): Observable<string> ;
}
