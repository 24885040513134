import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private auth: AuthenticationService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
      if (this.auth.authenticated) { return true; }

      return this.auth.currentUserObservable
           .take(1)
           .map(user => !!user)
           .do(loggedIn => {
             if (!loggedIn) {
               console.log("access denied")
               this.router.navigate(['/']);
             }
         })

  }


  // canActivate(): Observable<boolean> {

  //   return this.auth.authInfo
  //   .pipe(
  //       take(1),
  //       map(user => !!user),
  //       tap(signedIn => {
  //               if (!signedIn) {
  //                   console.log('access denied');
  //                   this.router.navigate(['/']);
  //               }else{
  //                 this.router.navigate(['setup/welcome']);
  //               }
  //           })
  //   );
  // }

}
