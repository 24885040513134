// Import Observable, Firebase and AngularFire
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  public authInfo: Observable<firebase.User>;

  user;
  userName:string;

  uid:string;

  userEmail:string;

  provider = new firebase.auth.OAuthProvider('microsoft.com');

 constructor(private afAuth: AngularFireAuth, 
  private router: Router) {
    this.authInfo = this.afAuth.authState;
    this.afAuth = afAuth;
  }

  login() {
    var hostname = window.location.host;
    var adTenant; 
    if(hostname.includes("dev") || hostname.includes("qa") || hostname.includes("localhost")){
      adTenant = 'a744b2fc-9cb1-4d03-8ea8-91043d5e1e66';
    }else{
      adTenant = '7e53ec4a-d325-4228-9e0e-a55a6b8892d5';
    }

    localStorage.setItem('loginStarted', '1');

    // this.provider = new firebase.auth.OAuthProvider('microsoft.com');

    this.provider.setCustomParameters({
      tenant: adTenant
    });


    // Sign in using a redirect.
    firebase.auth().getRedirectResult().then((result) => { // <--- Here!
      console.log(result);
      if (result.credential) {
        var token = result.credential;
        console.log('token',token);
      }
      var user = result.user;
      user.getIdToken().then(function(idToken) {  // <------ Check this line
			  console.log("idtoken" + idToken); // It shows the Firebase token now
     });
      console.log('user',user);

    });
    // Start a sign in process for an unauthenticated user.
    firebase.auth().signInWithRedirect(this.provider);

      //  firebase.auth().getRedirectResult()
      //   .then(function(result) {
      //     firebase.auth().getRedirectResult()
      //     console.log('firebase.auth.OAuthCredential', firebase.auth.OAuthCredential);
      //     if(result.user){
      //       this.router.navigate(['/schedule']);
      //     }
      //   })
      //   .catch(function(error) {
      //     // Handle error.
      //   });

        
      }


    // Returns true if user is logged in
public authenticated() {
  this.authInfo.subscribe(x => {
    if(x!=null){
      this.userEmail = x.email;
      if(this.userEmail!=null){
        localStorage.setItem('userEmail', x.email);
      }else{
        this.userEmail = firebase.auth().currentUser.email;
        localStorage.setItem('userEmail', x.email);
      }
      console.log(x.email)
    }
  });
  return this.userEmail;
}

public getUID() {
  this.authInfo.subscribe(x => {
    this.uid = x.uid;
    console.log(x.uid)
  });
  return this.uid;
}

get currentUserObservable(): any {
  return this.afAuth;
}

  logout() {
    console.log("current User", firebase.auth().currentUser);
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(['/']);
      localStorage.removeItem('loginStarted');
      console.log('logged out');
    });
  }
}
