import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-differentiate',
  templateUrl: './differentiate.component.html',
  styleUrls: ['./differentiate.component.scss']
})
export class DifferentiateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
