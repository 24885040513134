import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-tips',
  templateUrl: './site-tips.component.html',
  styleUrls: ['./site-tips.component.scss']
})
export class SiteTipsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
