import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBreadcrumbService, IBreadcrumb } from 'src/app/services/ibreadcrumb/ibreadcrumb.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb/breadcrumb.service';
import { VirtualTimeScheduler } from 'rxjs';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  providers: [
    { provide: IBreadcrumbService, useClass: BreadcrumbService }
  ]
})
export class WelcomeComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private iBreadcrumbService: IBreadcrumbService,
    ) { }

  //  url: string = this.route.snapshot.url[0].path + '/' + this.route.snapshot.children[0].url[0].path;

  ngOnInit() {
    // let root = this.route.root;
    // let breadcrumb: IBreadcrumb = {
    //   label: this.route.snapshot.data['ROUTE_DATA_BREADCRUMB'],
    //   url: this.url,
    //   children: []
    // };

    // let crumbs = [];
    // crumbs.push(breadcrumb);

    // let whatever = this.iBreadcrumbService.getBreadcrumbs(root, this.url, crumbs);
    // console.log('whatever');
    // console.log(whatever);

    //this.iBreadcrumbService.sendBreadcrumbs(this.url);
  }

}
