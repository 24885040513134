import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import Practicum from 'src/models/practicum';


export interface Item { name: string; }

export interface User {banner_id: string,email_address:string,first_name:string,last_name:string,practicum: Observable<Practicum[]> }



@Injectable({
  providedIn: 'root'
})
export class FirestoreDataService {

  private itemsCollection: AngularFirestoreCollection<Item>;

  private userCollection: AngularFirestoreCollection<User>;
  public authInfo: Observable<firebase.User>;
  items: Observable<Item[]>;
  item: Item = {
    name: ''
  }

  userData: Observable<User[]>;

  ngOnInit() {
  }

  constructor(public db: AngularFirestore,public auth: AuthenticationService) {

    this.itemsCollection = db.collection<Item>('items');
    this.items = this.itemsCollection.valueChanges();
    this.userCollection = db.collection<User>('user');
    this.userData = this.userCollection.valueChanges();

    // this.userData.forEach(user => {
    //   console.log(user[0].banner_id);
    // });

    this.authInfo = auth.authInfo;

    this.items.subscribe(
      val =>console.log(val)
    );


  }

  getItems() {
    return this.items;
  }

  addItem(Item) {
    this.itemsCollection.add(Item);
  }

   onSubmit() {
    if(this.item.name!=''){
      var name = this.item.name;
      this.addItem(this.item);
      this.item.name = '';
    }
  }
}
