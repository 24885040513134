import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';
import { AngularFirestore, AngularFirestoreCollection,AngularFirestoreDocument } from '@angular/fire/firestore';

import Practicum from '../../../../../models/practicum';
import User from '../../../../../models/user';
import data from '../../../../../assets/data/checklist.json';
import { FirestoreDataService } from 'src/app/services/firestore-data.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [CookieService]
})
export class ScheduleComponent implements OnInit {

  private practicumCollection: AngularFirestoreCollection<Practicum>;

  practicum: Observable<Practicum[]>;


  private userCollection: AngularFirestoreCollection<User>;
  users: Observable<User[]>;


  userEmail:String;
  uid:String;
  
  constructor(
    private cookieService: CookieService, 
    public db: AngularFirestore, 
    private firestoreData: FirestoreDataService,
    private auth: AuthenticationService) { 
  }

   // Display Checklist
   show = false;

  // Checklist Data
  items = [];
  monthsout = [];
  practicums =new Array();
  selectedPracticums:AngularFirestoreCollection<Practicum>;

  // Modal Data
  practicumdates = data.practicumDates;

  ordinals = [ "first", "second", "third", "fourth",
              "fifth", "sixth",  "seventh", "eighth",
               "ninth", "tenth" ];
  amountOfOrdinals = this.ordinals.slice(0, data.numPracticums);

  //Cookie Data
  allCookies: {} = this.cookieService.getAll();
  cookiePracticums = [];
  databasePracticums = [];
  collection: any;

  compareFn(a, b) {
    //console.log(a, b, a && b && a.date == b.date);
    return a && b && a.date == b.date;
   }


  /*
  Begin Create Practicum dates and save in database
  */
  createChecklist(selectedDates) {
    console.log('this.selectedPracticums:' +this.selectedPracticums);

    // userEmail
    this.userEmail = localStorage.getItem('userEmail');
    console.log("this.userEmail: " +this.userEmail);
    //uid
    this.uid = localStorage.getItem('uid');
    console.log("this.userEmail: " +this.uid);
    console.log("firebase.auth().currentUser: " +firebase.auth().currentUser.uid);

    this.selectedPracticums = selectedDates;

    // populate with checklist data from checklist.json
    this.items = data.items;

    selectedDates.forEach(practicum => {

      if(practicum.items!=null && practicum.items!=undefined){
        // practicum.items.forEach(i => {

        //   let timeline = {
        //     "formattedDate": moment(practicum.date).subtract(i.monthsout, 'months').format('MMMM YYYY'),
        //     "monthsOut": i.monthsout,
        //     "steps": i.steps
        //   };
  
        //   practicum.items.push(timeline);
        // });
        this.cookieService.set(practicum.startDate, practicum.date);
      }else{
        practicum.items = [];
        console.log(practicum.date);
        practicum.startDate = moment(practicum.date).format('MMMM YYYY');
  
        this.items.forEach(i => {
  
          let timeline = {
            "formattedDate": moment(practicum.date).subtract(i.monthsout, 'months').format('MMMM YYYY'),
            "monthsOut": i.monthsout,
            "steps": i.steps
          };
  
          practicum.items.push(timeline);
        });
        this.cookieService.set(practicum.startDate, practicum.date);
      }
      // this.practicumCollection.add(practicum);
    });

    this.db.collection('user').doc(firebase.auth().currentUser.uid)
    .set({uid:firebase.auth().currentUser.uid,email_address:this.userEmail,Practicum:this.selectedPracticums});

    if(false) {

      if(this.userEmail!=undefined){
        var userRef = this.db.collection('user').doc(this.userEmail.toString());
        // userRef.update({uid:firebase.auth().currentUser.uid,email_address:this.userEmail, banner_id:'A00254963',Practicum:this.selectedPracticums })
    
        userRef.valueChanges();
        userRef.set({uid:firebase.auth().currentUser.uid,email_address:this.userEmail,Practicum:this.selectedPracticums},{merge: true})
        // var userRef = this.db.collection('user').doc('henry.zzdemostudent@dev.waldenu.edu');
        // userRef.update({uid:firebase.auth().currentUser.uid,email_address:this.userEmail, banner_id:'A00254961',Practicum:this.selectedPracticums});
      }
    }
    


    this.show = true;
    console.log('this.selectedPracticums: ');
    console.log(this.selectedPracticums);
  }

   /*
  End Create Practicum dates and save in database
  */

  /*
    Begin Load Practicum data from database and display on UI
  */
  loadChecklist(selectedDates) {
    this.selectedPracticums = selectedDates;
    console.log('selectedDates:'+selectedDates);
    this.show = true;
    console.log('this.selectedPracticums: ');
    console.log(this.selectedPracticums);  }

  clearCookie() {
    console.log('clearing cookie...');
    this.cookieService.deleteAll();
  }

  /*
    End Load Practicum data from database and display on UI
  */

  /*
  Begin Select/unselect checkbox for each practicum dates and update in database
  */
  onSelect(practicum) {
  
  console.log("practicum data:"+practicum);
  this.userEmail = localStorage.getItem('userEmail');
  this.uid = localStorage.getItem('uid');
  // userEmail
  // console.log("this.userEmail: " +this.userEmail);
  // uid
  // console.log("this.uid: " +this.uid);

  // console.log("firebase.auth().currentUser: " +firebase.auth().currentUser.uid);
  
  if(this.userEmail!=undefined){
   var userRef = this.db.collection('user').doc(firebase.auth().currentUser.uid);
    // userRef.update({uid:firebase.auth().currentUser.uid,email_address:this.userEmail, banner_id:'A00254963',Practicum:this.selectedPracticums })
    userRef.valueChanges();
    userRef.set({uid:firebase.auth().currentUser.uid,Practicum:this.selectedPracticums},{merge: true})
    .catch(function(error) {
      console.error("Error writing document: ", error);
  });

  }
}
/*
  End Select/unselect checkbox for each practicum dates and update in database
  */

 /*
  Begin get Practicum data from Database
  */ 
getPracticumFromDatabase(value){

  this.userEmail = localStorage.getItem('userEmail');
  // console.log('this.userEmail:'+this.userEmail);
  var userData = this.db.collection("user", ref => ref.where('email_address', '==', this.userEmail)).valueChanges();
  var user
  var cookiePracticums = [];
  userData.subscribe(users => { 
    user = users[0];
    if(user!=undefined) {
      this.practicumCollection = user.Practicum;
      // console.log("practicumCollection:" +this.practicumCollection);
      
        if(user.Practicum!=undefined && user.Practicum!=null){
          user.Practicum.forEach(practicumData => {
            var practicum = new Practicum();
            practicum.items = practicumData.items;
            practicum.date = practicumData.date;
            practicum.formattedDate = practicumData.startDate;
            practicum.text = practicumData.text;
            this.cookiePracticums.push(practicum);
          });
        }
      
          if(value == 'init'){
            this.loadChecklist(this.practicumCollection);
          }else{
            this.cookiePracticums = cookiePracticums;
          }
    }

  });

}

 /*
  End get Practicum data from Database
  */ 

   /*
  Begin nginit()
  */ 

  ngOnInit(): void {
    //start with empty checklist
    this.items = [];
    var cookiePracticums = [];
    // console.log("practicumDates:"+this.practicumdates);

    var cookiePracticums = [];
      // console.log("authenticated init:" + this.auth.authenticated());
      this.userEmail = localStorage.getItem('userEmail');
      // console.log('this.userEmail:'+this.userEmail);
    
      var value;
      value = 'init';
      this.getPracticumFromDatabase(value);
  }
   /*
  End nginit()
  */ 

  
}
