// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
 /*    apiKey: "AIzaSyDsbvE1JYztJkpe-VABJPKljaEf9mzlnjU",
   authDomain: "mytestproject-fcfff.firebaseapp.com",
    databaseURL: "https://mytestproject-fcfff.firebaseio.com",
    projectId: "mytestproject-fcfff",
    storageBucket: "mytestproject-fcfff.appspot.com",
    messagingSenderId: "463613234908",
    appId: "1:463613234908:web:4f03b7fc10579104"

  apiKey: "AIzaSyBFVKx3t4EBFE4HBPg2p-MoI9sxR4QJCDA",
  authDomain: "nursing-preceptor.firebaseapp.com",
  databaseURL: "https://nursing-preceptor.firebaseio.com",
  projectId: "nursing-preceptor",
  storageBucket: "nursing-preceptor.appspot.com",
  messagingSenderId: "566398222204",
  appId: "1:566398222204:web:f894f0191cbf3898"
*/

  apiKey: "AIzaSyAQNFUpRrV_36gvU5S38EbFD_ttJxnN2gg",
  authDomain: "walden-nursing-preceptor-dev.firebaseapp.com",
  databaseURL: "https://walden-nursing-preceptor-dev.firebaseio.com",
  projectId: "walden-nursing-preceptor-dev",
  storageBucket: "walden-nursing-preceptor-dev.appspot.com",
  messagingSenderId: "88267839696",
  appId: "1:88267839696:web:e92c4b432e2da6f4"

	}
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
