import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  user;
  userLoggedIn;

  constructor(private auth: AuthenticationService, private router: Router) {
    this.user = auth.authInfo;
    this.user.subscribe(result => {
      this.userLoggedIn = result ? true : false;
    });
  }

  ngOnInit() {
  }

  getStartedHandler() {
    window.dataLayer.push({
      'event': 'getStartedClicked'
    });

    return this.userLoggedIn ? this.goToChecklist() : this.login();
  }

  login() {
    this.auth.login();
  }

  goToChecklist() {
    this.router.navigateByUrl('/checklist');
  }

}
