import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

import { IterableChangeRecord_ } from '@angular/core/src/change_detection/differs/default_iterable_differ';
import { AuthenticationService } from '../../services/authentication.service';
//import { database } from 'firebase';

export interface Item { name: string; }


@Component({
  selector: 'app-protected',
  templateUrl: './protected.component.html',
  styleUrls: ['./protected.component.css']
})
export class ProtectedComponent implements OnInit {

  private itemsCollection: AngularFirestoreCollection<Item>;
  public authInfo: Observable<firebase.User>;
  items: Observable<Item[]>;
  item: Item = {
    name: ''
  }

  ngOnInit() {
  }

  constructor(public db: AngularFirestore,private auth: AuthenticationService) {

    this.itemsCollection = db.collection<Item>('items');
    this.items = this.itemsCollection.valueChanges();

    this.authInfo = auth.authInfo;

    this.items.subscribe(
      val =>console.log(val)
    );


  }

  getItems() {
    return this.items;
  }

  addItem(Item) {
    this.itemsCollection.add(Item);
  }

   onSubmit() {
    if(this.item.name!=''){
      var name = this.item.name;
      this.addItem(this.item);
      this.item.name = '';
    }
  }
}
