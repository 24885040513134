import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { Router, ActivatedRoute, PRIMARY_OUTLET, NavigationEnd } from "@angular/router";
import menudata from '../../../assets/data/sections.json';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  locpath: string = '';
  user;
  route;

  constructor(location: Location, private router: Router, act: ActivatedRoute, private auth: AuthenticationService ) {
    this.locpath = location.path().replace(/^\/+/g, '');
    this.user = auth.authInfo;
    this.route = act;

    router.events.subscribe((val) => {
      if (!(val instanceof NavigationEnd)) return;

      this.open = false;
      this.locpath = location.path().replace(/^\/+/g, '');
      this.isHome = this.locpath === '';
      this.isChecklist = this.locpath === 'checklist';
    });
  }


  open: boolean = false;
  isHome: boolean = false;
  isChecklist: boolean = false;
  sections = menudata.sections;
  btmnavitems = menudata.btmnavitems;


  isCurrentSection(s, index) {
    let that = this;

    if(s[index].subsections) {
      let section = s[index].subsections.map(function(subsection){

        if(that.locpath === subsection.pagename) {
          return true;
        }
        return false;
      });

      return section.indexOf(true) > -1;
    } else {

      let section = s.map(function(item) {
        if(that.locpath === item.pagename) {
          return true;
        }
        return false;
      })

      return section.indexOf(true) > -1;
    }


  }

  isCurrentLocation(loc) {
    return this.locpath === loc.toLowerCase();
  }


  toggle(){
    this.open = !this.open;
  }

  ngOnInit() {
    this.isHome = this.locpath === '';
    this.isChecklist = this.locpath === 'checklist';
  }

  logout() {
    this.auth.logout();
  }

}
