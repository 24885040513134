import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-checklist-detail',
  templateUrl: './checklist-detail.component.html',
  styleUrls: ['./checklist-detail.component.scss']
})

export class ChecklistDetailComponent implements OnInit {
  @Input() task;
  @Input() open: Boolean;
  @Output() closed = new EventEmitter<boolean>();

  slideOutPane;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.listen(document, 'click', ev => {

      if (!ev.target.href || ev.target.href.indexOf('http') < 0) return;

      ev.preventDefault();
      ev.stopPropagation();

      console.log(ev);

      window.dataLayer.push({
        'linkName': ev.target.innerText,
        'event': 'outboundLinkClicked'
      });

      window.open(ev.target.href);
    });
  }

  close() {
    this.closed.emit(true);
  }

  taskCompleteHandler(task) {
    task.complete = true;
    window.dataLayer.push({
      'taskName': task.title,
      'event': 'markAsCompleteClicked'
    });
  }
}
