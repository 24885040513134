import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, PRIMARY_OUTLET } from "@angular/router";
import { Observable, of } from 'rxjs';

import { IBreadcrumb, IBreadcrumbService } from '../ibreadcrumb/ibreadcrumb.service'
import menudata from '../../../assets/data/sections.json';


@Injectable()
export class BreadcrumbService implements IBreadcrumbService {
  sendBreadcrumbs(routepath: string): Observable<string> {
    throw new Error("Method not implemented.");
  }

  constructor() { }
  //ROUTE_DATA_BREADCRUMB: string = "breadcrumb";

  sectiondata = menudata;


  getBreadcrumbs(route: ActivatedRoute, url: string = ""): Observable<IBreadcrumb> {

    let children: ActivatedRoute[] = route.children;

    if (children.length === 0) return;

    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      //verify the custom data property "breadcrumb" is specified on the route
      // if (!child.snapshot.data.hasOwnProperty(this.ROUTE_DATA_BREADCRUMB)) {
      //   return this.getBreadcrumbs(child, url);
      // }

      let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");

      let breadcrumb = {
        "section": '',
        "subsections": [],
        "currentURL": ''
      };

      this.sectiondata.sections.forEach(function (section, i) {
        section.subsections.forEach(function (page, i) {
          if (page.pagename === routeURL) {
            breadcrumb.currentURL = page.pagename;

            if (section.subsections[i].pagename === routeURL) {
              breadcrumb.section = section.section;
              breadcrumb.subsections = section.subsections;
            }
          }
        });
      });

      return of(breadcrumb);
    }
  }
}

